import React, { useState } from 'react';
import logo from './Media/MyReceiptLogo.png';
import './App.css';
import './Components/HowItWorks/HowTextStyles.css';
import './Components/PersonBar/PersonBarStyles.css';
import InspoText from './Components/InspoText/InspoText';
import RenderSingleImage from './Components/DispPhoneImages/DispSingleImage';
import HowText from './Components/HowItWorks/TopHowText';
import ShowDiagram from './Components/HowItWorks/Diagrams';
import { ConsumerBar, MerchantBar } from './Components/PersonBar/PersonBar';
import Signupform from './Components/SignUpForm/SignUpForm';

function App() {
  // Define states to remember what diagram to be showing
  const [diagramState, setDiagramState] = useState('Consumer')

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='Top'>
        <div className='TopBodyLeft'>
          <InspoText />
          <HowText />
        </div>
        <div className='TopBodyRight'>
          <RenderSingleImage />
        </div>
      </div>
      <div className='Mid'>
        <div className='MidHeader'>
          <h1 className='HIWHeader'>
            HOW IT WORKS
          </h1>
        </div>
        <div className='MidBody'>
          <div className="PersonBarStyle">
            <button className='Consumer PersonBarButton' onClick={() => setDiagramState('Consumer')}>
              <ConsumerBar state={diagramState} />
            </button>
            <button className='Merchant PersonBarButton' onClick={() => setDiagramState('Merchant')}>
              <MerchantBar state={diagramState} />
            </button>
          </div>
          <ShowDiagram diagramState={diagramState} />
        </div>
      </div>
      <div className='Bottom PageSize'>
        <div className='BottomContent'>
          <Signupform />
          <hr className='BottomLine'></hr>
        </div>
        <p className='CopyrightStyle'>
          © MyReceipt 2022
        </p>
      </div>

    </div >
  );
}

export default App;

