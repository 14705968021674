import React from 'react'
import './InspoTextStyle.css'

function InspoText() {
    return (
        <div className='InspoTextStyle'>
            <p className="PreHeader">
                BUILDING
            </p>
            <h2 className="Header">
                SUSTAINABLE ALTERNATIVES
            </h2>
            <p className="PreHeader">
                TO
            </p>
            <h2 className="Header">
                YESTERDAYS TECHNOLOGY
            </h2>
        </div>
    )
}

export default InspoText
