import React from 'react'
import './PersonBarStyles.css'

function ConsumerBar(props) {
    const state = props.state

    if (state === 'Consumer') {
        return (
            <div className="PersonBarStyle">
                <h3 className='Consumer Underline'>
                    Consumer
                </h3>
            </div>
        )
    } else if (state === 'Merchant') {
        return (
            <div className="PersonBarStyle">
                <h3 className='Merchant'>
                    Consumer
                </h3>
            </div>
        )
    }
}

function MerchantBar(props) {
    const state = props.state

    if (state === 'Consumer') {
        return (
            <div className="PersonBarStyle">
                <h3 className='Consumer'>
                    Merchant
                </h3>
            </div>
        )
    } else if (state === 'Merchant') {
        return (
            <div className="PersonBarStyle">
                <h3 className='Merchant Underline'>
                    Merchant
                </h3>
            </div>
        )
    }
}

export { ConsumerBar, MerchantBar }
