import React from 'react'
import ReceiptScreen from '../../Media/Cropped/ReceiptScreen.png'
import StatsScreen from '../../Media/Cropped/StatsScreen.png'
import LoadingScreen from '../../Media/Cropped/LoadingScreen.png'
import HomeScreen from '../../Media/Cropped/HomeScreen.png'
import AccountScreen from '../../Media/Cropped/AccountScreen.png'
import './PhoneImageStyles.css'

class ShowImages extends React.Component {
    constructor(props) {
        super(props);
        this.switchImage = this.switchImage.bind(this);
        this.state = {
            currentImage: 0,
            images: [
                LoadingScreen,
                HomeScreen,
                ReceiptScreen,
                AccountScreen,
                StatsScreen
            ]
        };
    }


    switchImage() {
        if (this.state.currentImage < this.state.images.length - 1) {
            this.setState({
                currentImage: this.state.currentImage + 1
            });
        } else {
            this.setState({
                currentImage: 0
            });
        }
        return this.currentImage;
    }

    componentDidMount() {
        setInterval(this.switchImage, 4000);
    }

    render() {
        return (
            <div>
                <img
                    src={this.state.images[this.state.currentImage]}
                    className='SingleImage'
                    alt="cleaning images"
                />
            </div>

        );
    }
}

export default ShowImages
