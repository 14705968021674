import React, { useState } from 'react'
import './SignUpFormStyles.css'
import { db } from './firebaseSignup'
import { collection, doc, setDoc } from "firebase/firestore"

const Signupform = () => {

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [type, setType] = useState("")
    const [location, setLocation] = useState("")
    const [mobile, setMobile] = useState("")
    const [message, setMessage] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            name: name,
            type: type,
            company: company,
            location: location,
            mobile: mobile,
            message: message
        }

        setDoc(doc(db, "WebsiteSignups", email), data)

        // Reset the form to original state
        setEmail("")
        setCompany("")
        setName("")
        resetConsumerButton()
        resetMerchButton()
    }

    const resetMerchButton = () => {
        document.getElementById("MerchantCheck").checked = false
        document.getElementById("CompanyInput").style.display = "none"
    }

    const resetConsumerButton = () => {
        document.getElementById("ConsumerCheck").checked = false
    }

    const displayCompanyBox = () => {
        // Function used to display the company box if they say they are a merchant
        document.getElementById("CompanyInput").style.display = "block"
    }

    return (
        <form className='SignupFormStyle' onSubmit={handleSubmit}>
            <h1 className='FormTitle'>
                Get notified when the app launches!
            </h1>
            <div>
                <label className='LabelStyle'>
                    Name:
                </label>
                <br></br>
                <input
                    className='EmailInput'
                    value={name}
                    onChange={(e) =>
                        setName(e.target.value)
                    }
                />
            </div>
            <div className='inputSpacing'>
                <label className='LabelStyle'>
                    Email Address:
                </label>
                <br></br>
                <input
                    className='EmailInput'
                    value={email}
                    onChange={(e) =>
                        setEmail(e.target.value)
                    }
                />
            </div>
            <h2 className='CheckboxTitle'>
                I am a:
            </h2>
            <label className='CheckboxLabelStyle'>
                <input
                    id="ConsumerCheck"
                    className='CheckboxStyle'
                    type="radio"
                    value="Consumer"
                    onChange={(e) => {
                        setType(e.target.value)
                        resetMerchButton()
                    }
                    }
                />
                Consumer
            </label>
            <br></br>
            <label className='CheckboxLabelStyle'>
                <input
                    id="MerchantCheck"
                    className='CheckboxStyle'
                    type="radio"
                    value="Merchant"
                    onChange={(e) => {
                        setType(e.target.value)
                        resetConsumerButton()
                        displayCompanyBox()
                    }
                    }
                />
                Merchant
            </label>
            <div id='CompanyInput' className='companyInput'>
                <div className='flexStyle'>
                    <div className='inputSpacing flexChild'>
                        <label className='LabelStyle'>
                            Company:
                        </label>
                        <br></br>
                        <input
                            className='EmailInput'
                            value={company}
                            onChange={(e) =>
                                setCompany(e.target.value)
                            }
                        />
                    </div>
                    <div className='inputSpacing flexChild'>
                        <label className='LabelStyle'>
                            Location:
                        </label>
                        <br></br>
                        <input
                            className='EmailInput'
                            value={location}
                            onChange={(e) =>
                                setLocation(e.target.value)
                            }
                        />
                    </div>
                </div>
                <div className='inputSpacing'>
                    <label className='LabelStyle'>
                        Phone Number:
                    </label>
                    <br></br>
                    <input
                        className='EmailInput'
                        value={mobile}
                        onChange={(e) =>
                            setMobile(e.target.value)
                        }
                    />
                </div>
                <div className='inputSpacing'>
                    <label className='LabelStyle'>
                        Message:
                    </label>
                    <br></br>
                    <input
                        className='EmailInput'
                        value={message}
                        onChange={(e) =>
                            setMessage(e.target.value)
                        }
                    />
                </div>
            </div>
            <br></br>
            <h4>
                Marketing Permissions
            </h4>
            <p>
                MyReceipt will use the information you provide on this form to contact you with company updates and marketing
                <br></br>
                <br></br>
                By subscribing, you agree to receive emails from MyReceipt. You can unsubscribe from these emails at any time using the footer in our email
            </p>

            <button className='SignupButtonStyle'>
                Subscribe
            </button>
        </form>
    )
}

export default Signupform
