import React from 'react'
import './HowTextStyles.css'

function HowText() {
    // Function to render the how it works text that is going on the landing page of the website
    return (
        <div className='HowTextStyle'>
            <p>
                Paper receipts don’t belong in a technology driven world. They are inconvenient,
                fragile, wasteful, and damaging to the environment.
            </p>
            <p>
                My Receipt removes the need for paper receipts by creating automated digital
                receipts for in-store transactions. This increases convenience for consumers while
                helping the world transition to a paperless future.
            </p>
        </div>

    )
}

export default HowText
