// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCPMeu5blWsWQAXrzukD3dr1HZwIB1IrxQ",
    authDomain: "myreceipt-4fe79.firebaseapp.com",
    databaseURL: "https://myreceipt-4fe79-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "myreceipt-4fe79",
    storageBucket: "myreceipt-4fe79.appspot.com",
    messagingSenderId: "28406320042",
    appId: "1:28406320042:web:eef60c226fc111c05a79d3",
    measurementId: "G-L4456MK41K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db }
