import React from 'react'
import ConsumerDiagram from '../../Media/ConsumerDiagram.png';
import MerchantDiagram from '../../Media/MerchantDiagram.png'
import './HowTextStyles.css';

function ShowDiagram(props) {
    const diagramState = props.diagramState

    if (diagramState === 'Consumer') {
        return (
            <img src={ConsumerDiagram} className='HIWDiagramStyle ConsumerDiagram' alt="ConsumerDiagram" />
        )
    } else {
        return (
            <img src={MerchantDiagram} className='HIWDiagramStyle MerchantDiagram' alt="MerchantDiagram" />
        )
    }
}

export default ShowDiagram
